/** @format */

import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { from, Observable } from 'rxjs';
import { SesioNode } from '../_classes/sesio-node/sesio-node.class';
import { ColumnOption } from '../_components/datagrid/column-def.class';
import { IDatatableOptions } from '../_components/datagrid/datatable.class';
import { SesioNodeHatVersion } from '../_constants/sesio-node/sesio-node-hat-version';
import { SesioNodeKind } from '../_constants/sesio-node/sesio-node-kind';
import { SesioNodeStatus } from '../_constants/sesio-node/sesio-node-status';
import { CrudServiceBuild, FilterType } from './crud.service';
import { MethodLogger } from './logger.service';

export interface ISesioNodeFilter extends FilterType {
  kinds?: (SesioNodeKind | string)[];
  organizationalUnitId?: string;
  // realEstateStructureId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SesioNodeService extends CrudServiceBuild<SesioNode, ISesioNodeFilter>(SesioNode, '/sesio-node') {
  getDatagridColumn(property: string = 'node'): ColumnOption {
    return {
      property,
      searchProperty: `${property}.__search`,
      linkedProperties: [`${property}.__search`, `${property}.reference`, `${property}.label`],
      label: $localize`sésiO nOde`,
      searchable: true,
      sortable: true,
      displayWith: record => get(record, `${property}.label`, get(record, `${property}.reference`, '')),
    };
  }

  @MethodLogger()
  async syncGpioConfig(id: string): Promise<boolean> {
    const path = `${this.path}/${id}/sync-config`;
    return this.httpService.patch(path);
  }

  @MethodLogger()
  async countByStatus(filter?: ISesioNodeFilter): Promise<{ status: SesioNodeStatus; count: number }[]> {
    const path = `${this.path}/count-by-status`;
    return this.httpService.post(path, this.getFilterQuery(filter));
  }

  @MethodLogger()
  async countByKind(
    filter?: ISesioNodeFilter,
  ): Promise<{ kind: SesioNodeKind; count: number; data: { hat: SesioNodeHatVersion; count: number }[] }[]> {
    const path = `${this.path}/count-by-kind`;
    return this.httpService.post(path, this.getFilterQuery(filter));
  }

  @MethodLogger()
  async countByCustomerAccountOrganization(): Promise<
    { customerAccount: string; count: number; data: { organization: string; count: number }[] }[]
  > {
    const path = `${this.path}/count-by-customer-account-organization`;
    return this.httpService.get(path);
  }

  payloadDatatable(query: IDatatableOptions, filter?: { device?: string }): Observable<any> {
    const path = `${this.path}/payload-datatable`;
    return from(this.httpService.post(path, { query, filter }));
  }
}
